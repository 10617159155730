<template>

<div class="cta">

	<div class="cta-logo" />

	<div class="cta-title">Get in Touch</div>

	<div class="cta-text">Do you have a question?  Would like more information? Just want to talk? Please call or email us today.</div>

	<div class="cta-phone"><app-icon s="phone" />{{ $store.getters['context'].phone }}</div>

	<div class="cta-email"><a :href="'mailto:' + $store.getters['context'].email">{{ $store.getters['context'].email }}</a></div>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.cta {
	background-color: #f5f5f5;
	border-radius: 21px;
	padding: 78px 10px 10px 10px;
	text-align: center;
	margin-top: 68px;
	margin-bottom: 40px;
}

.cta-logo {
	width: 96px;
	height: 96px;
	position: absolute;
	top: -48px;
	left: 50%;
	margin-left: -48px;
	background-size: contain;
	background-image: url(~@/assets/heart.border.png);
	border-radius: 50%;
}

.cta-title {
	color: #1a234c;
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 20px;
}

.cta-text {
	color: #1a234c;
	font-size: 16px;
	padding: 0px 10px;
	line-height: 24px;
	margin-bottom: 20px;
}

.cta-phone,
.cta-email {
	color: #1a234c;
	font-size: 24px;
	font-weight: bold;
	background-color: #fff;
	line-height: 48px;
	border-radius: 24px;
	margin-bottom: 10px;
}

.cta-phone {
	display: flex;
	justify-content: center;
	align-items: center;
}

.cta-phone .icon {
	display: inline!important;
	color: #1277D4;
	font-size: 16px;
	margin-right: 10px;
}

.cta-email {
	font-size: 14px;
}

.cta-email:hover {
	text-decoration: underline;
}

</style>
